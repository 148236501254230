import { Fragment, useCallback } from "react";
import { useSnackbar } from "notistack";
import {
  useForm,
  useFormContext,
  useWatch,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  UseFieldArrayReplace,
} from "react-hook-form";
import { useStateContext } from "contexts/auth-context";

import { useDisable } from "hooks/use-disable";
import { useGoodsIssueItemList } from "hooks/Inventory/use-issue-item-list";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { goodsIssueTraceEntryFormatter } from "utils/Formatter/Inventory/GoodsIssue";

import {
  Traceability,
  TraceEntryType,
  useTraceEntriesAggridQuery,
  TraceEntriesAggridQuery,
  useItemSkuQtysQuery,
  ItemSkuQtysQuery,
} from "generated/wms";
import {
  Box,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  IconButton,
} from "@mui/material";

import SerialList from "./SerialList";
import TotalPostedQuantityCell from "./TotalPostedQuantityCell";

import ControlledSelect from "components/Controller/ControlledSelect";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";

import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedAvatar from "components/Custom/CustomizedAvatar";

import ClearIcon from "@mui/icons-material/Clear";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

import { IGoodsIssue } from "types/Inventory/goodsIssue";
import { IBarcodeForm, ITraceEntry } from "types/Inventory";

import { formatDateTimeNoAMPM } from "utils/Date";
import { checkIsNotDraftOrEmptyStatus } from "utils/Global";

// const options: IMenuOption[] = [
//   {
//     value: "นำออก Excel",
//     disabled: false,
//   },
//   {
//     value: "นำออก CSV",
//     disabled: false,
//   },
//   // {
//   //   value: "พิมพ์ทั้งหมด",
//   //   disabled: false,
//   // },
// ];

type Props = {
  fields: FieldArrayWithId<IGoodsIssue, "trace_entry_list", "id">[];
  append: UseFieldArrayAppend<IGoodsIssue, "trace_entry_list">;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsIssue, "trace_entry_list">;
  replace: UseFieldArrayReplace<IGoodsIssue, "trace_entry_list">;
};

const GoodsReceiveItemList = ({
  fields,
  append,
  remove,
  replace,
  update,
}: Props) => {
  const [disabled] = useDisable();
  const { enqueueSnackbar } = useSnackbar();
  const graphqlClientWms = createGraphQLClientWithMiddleware("wms");

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<IGoodsIssue>();

  const watchWarehouseId = useWatch({
    control,
    name: "source_warehouse_id",
  });

  const watchType = useWatch({
    control,
    name: "type",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const watchTraceEntryList = useWatch({
    control,
    name: "trace_entry_list",
  });

  const headers = useGoodsIssueItemList(Boolean(disabled));

  const {
    state: { authUser },
  } = useStateContext();

  const {
    control: barcodeControl,
    reset: resetBarcode,
    watch: watchBarcode,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
      source_bin_location_id: undefined,
    },
  });

  const { refetch: refetchTraceEntries } =
    useTraceEntriesAggridQuery<TraceEntriesAggridQuery>(
      graphqlClientWms,
      {
        aggridInput: {
          startRow: 0,
          endRow: 1,
          filterModel: {
            barcode: {
              filterType: "text",
              type: "equals",
              filter:
                watchBarcode("barcodeMobile")?.trim() ||
                watchBarcode("barcode").trim(),
            },
          },
        },
      },
      {
        enabled: false,
      }
    );

  const { refetch: refetchSkuQty } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphqlClientWms,
    {
      findManyInput: {
        where: {
          barcode: {
            equals:
              watchBarcode("barcodeMobile")?.trim() ||
              watchBarcode("barcode").trim(),
          },
        },
      },
    },
    {
      enabled: false,
    }
  );

  const onBarcodeSubmitHandler = useCallback(async () => {
    const { data } = await refetchTraceEntries();
    const { data: skuQtyData } = await refetchSkuQty();
    const warehouse_id = getValues("source_warehouse_id");
    // if (
    //   fields &&
    //   fields.length > 0 &&
    //   fields.some(
    //     (trace) =>
    //       trace.item_unique_id === skuQtyData?.ItemSkuQtys?.[0]?.item_unique_id
    //   ) &&
    //   watchType === "อื่นๆ"
    // ) {
    //   console.log("found duplicated from barcode");
    //   enqueueSnackbar(`QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`, {
    //     variant: "error",
    //     style: { whiteSpace: "pre-line" },
    //   });
    // } else {
    if (skuQtyData?.ItemSkuQtys) {
      if (skuQtyData.ItemSkuQtys.length > 0) {
        const filteredWarehouse = skuQtyData.ItemSkuQtys.filter(
          (itemSkuQty) => itemSkuQty?.warehouse_id === warehouse_id
        );

        if (filteredWarehouse.length > 0) {
          const formatBinLocations = skuQtyData.ItemSkuQtys.map((itemSku) => ({
            id: itemSku?.bin_location_id,
            name: itemSku?.bin_name,
            stock_qty: itemSku?.stock_qty,
            warehouse_id: itemSku?.warehouse_id,
          }));

          const filterBinLocations = formatBinLocations.filter(
            (bin) => bin.warehouse_id === watchWarehouseId
          );

          const traceEntries = data?.TraceEntriesAggrid?.results;
          const traceEntry = traceEntries
            ? traceEntries.find(
                (trace) => trace?.type === TraceEntryType.GoodReceive
              )
            : null;
          if (traceEntry) {
            const traceEntryType = traceEntry as ITraceEntry;
            const formatTrace = goodsIssueTraceEntryFormatter(
              traceEntryType,
              filterBinLocations,
              authUser
            );
            if (formatTrace) {
              if (formatTrace.traceability === Traceability.Serial) {
                const foundExisting = fields.findIndex(
                  (trace) => trace.item_unique_id === formatTrace.item_unique_id
                );
                if (foundExisting === -1) {
                  // append new trace (serial view)
                  const {
                    // posted_date,
                    serial_no,
                    source_bin_location,
                    source_bin_location_id,
                    scanned_by,
                    barcode,
                    ...otherTrace
                  } = formatTrace;

                  const formatTraceWithSerial = {
                    ...otherTrace,
                    serial_list: [
                      {
                        ...otherTrace,
                        // posted_date,
                        serial_no,
                        source_bin_location,
                        source_bin_location_id,
                        scanned_by,
                        barcode,
                      },
                    ],
                  };
                  append(formatTraceWithSerial);
                  enqueueSnackbar(`สแกน SN :\n${barcode}\nสำเร็จ`, {
                    variant: "success",
                    style: { whiteSpace: "pre-line" },
                  });
                } else {
                  // update serial
                  const currentTrace: ITraceEntry = getValues(
                    `trace_entry_list.${foundExisting}`
                  );
                  const totalCurrentTraceQty =
                    currentTrace.serial_list?.reduce(
                      (acc, serial) => acc + serial.qty,
                      0
                    ) ?? 0;

                  if (currentTrace && currentTrace.serial_list) {
                    const foundBarcode = currentTrace.serial_list.findIndex(
                      (serial) =>
                        serial.barcode ===
                        (watchBarcode("barcodeMobile")?.trim() ||
                          watchBarcode("barcode").trim())
                    );
                    if (foundBarcode === -1) {
                      const { document_item_qty, posted_qty, ...otherTrace } =
                        formatTrace;
                      const formatTraceWithDocument: ITraceEntry = {
                        ...otherTrace,
                        document_item_qty: currentTrace?.document_item_qty,
                        posted_qty: currentTrace?.posted_qty,
                        qty: Math.min(
                          Math.max(
                            otherTrace?.stock_qty ?? 0 - totalCurrentTraceQty,
                            0
                          ),
                          Math.max(
                            (currentTrace?.document_item_qty ?? 0) -
                              (currentTrace?.posted_qty ?? 0) -
                              totalCurrentTraceQty,
                            0
                          )
                        ),
                      };
                      update(foundExisting, {
                        ...currentTrace,
                        serial_list: [
                          ...currentTrace.serial_list,
                          formatTraceWithDocument,
                        ],
                      });
                      enqueueSnackbar(
                        `สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`,
                        {
                          variant: "success",
                          style: { whiteSpace: "pre-line" },
                        }
                      );
                    } else {
                      enqueueSnackbar(
                        `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                        {
                          variant: "error",
                          style: { whiteSpace: "pre-line" },
                        }
                      );
                    }
                  }
                }
              } else {
                // append new trace (normal view)
                const foundExisting = fields.findIndex(
                  (trace) => trace.item_unique_id === formatTrace.item_unique_id
                );
                if (foundExisting === -1) {
                  append(formatTrace);
                  enqueueSnackbar(`สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`, {
                    variant: "success",
                    style: { whiteSpace: "pre-line" },
                  });
                } else {
                  const foundUnscannedIndex = fields.findIndex(
                    (trace) =>
                      trace.item_unique_id === formatTrace.item_unique_id &&
                      trace.status === "is_active"
                  );
                  const currentTrace: ITraceEntry = getValues(
                    `trace_entry_list.${foundUnscannedIndex}`
                  );
                  const { document_item_qty, posted_qty, ...otherTrace } =
                    formatTrace;

                  const formatTraceWithDocument: ITraceEntry = {
                    ...otherTrace,
                    reference_line_item: currentTrace?.reference_line_item,
                    document_item_qty: currentTrace?.document_item_qty,
                    posted_qty: currentTrace?.posted_qty,
                    qty: Math.min(
                      otherTrace?.stock_qty ?? 0,
                      (currentTrace?.document_item_qty ?? 0) -
                        (currentTrace?.posted_qty ?? 0)
                    ),
                  };

                  if (foundUnscannedIndex !== -1) {
                    update(foundUnscannedIndex, formatTraceWithDocument);
                    enqueueSnackbar(
                      `สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`,
                      {
                        variant: "success",
                        style: { whiteSpace: "pre-line" },
                      }
                    );
                  } else {
                    enqueueSnackbar(
                      `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                      {
                        variant: "error",
                        style: { whiteSpace: "pre-line" },
                      }
                    );
                  }
                }
              }
            }
          }
        } else {
          // Warehouse not match
          enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในคลัง กรุณาเปลี่ยนคลัง", {
            variant: "error",
          });
        }
      } else {
        // Barcode not found
        enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่", {
          variant: "error",
        });
      }
    }
    // }
    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  }, [
    append,
    authUser,
    enqueueSnackbar,
    fields,
    getValues,
    refetchTraceEntries,
    refetchSkuQty,
    resetBarcode,
    update,
    watchBarcode,
    watchWarehouseId,
  ]);

  // const exportBarcodeHandler = (type?: string) => {
  //   // const formatData =
  //   //   goodReceiveData &&
  //   //   goodReceiveData.trace_entry_list &&
  //   //   goodReceiveData.trace_entry_list.map((trace) => {
  //   //     if (trace) {
  //   //       return {
  //   //         รหัสสินค้า: trace.item_unique_id,
  //   //         ชื่อสินค้า: trace.item_name,
  //   //         SN: trace.serial_no,
  //   //         Lot: formatDate(trace.lot_date),
  //   //         Barcode: trace.barcode,
  //   //         จำนวน: trace.qty,
  //   //         หน่วย: trace.uom?.name,
  //   //         สถานะ:
  //   //           trace.status === InventoryDocumentStatus.IsActive
  //   //             ? "รอสแกน"
  //   //             : "สแกนแล้ว",
  //   //       };
  //   //     } else {
  //   //       return true;
  //   //     }
  //   //   });
  //   // exportAsExcelBarcode(formatData, "Barcode" + id, undefined, type);
  //   // enqueueSnackbar("นำออกBarcodeสำเร็จ", {
  //   //   variant: "success",
  //   // });
  // };

  //   const printBarcodeHandler = async (traceEntryList: ITraceEntry[]) => {
  //     const formatValues = traceEntryList.map((trace) => ({
  //       productId: trace.item_unique_id,
  //       productName: trace.item_name,
  //       productSn: trace.serial_no,
  //       productLot: trace.lot_date,
  //       productQty: trace.qty,
  //       productUom: trace.uom?.name,
  //       productBarcode: trace.barcode,
  //     }));
  //     try {
  //       const res = await fetch(`http://localhost:4000/print`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(formatValues),
  //       });
  //       const data = await res.json();
  //       if (data.status === "success") {
  //         enqueueSnackbar("พิมพ์สำเร็จ", {
  //           variant: "success",
  //         });
  //       } else if (data.status === "error") {
  //         enqueueSnackbar("พบการขัดข้องในการพิมพ์", {
  //           variant: "error",
  //         });
  //       }
  //     } catch (err) {
  //       console.error(err);
  //       enqueueSnackbar("ไม่สามารถเชื่อมต่อกับเครื่องพิมพ์", {
  //         variant: "error",
  //       });
  //     }
  //   };

  //   const { scrollRef, showCamera, setShowCamera, setScanData, setIsInterval } =
  //     useBarcodeScanner(onBarcodeSubmitHandler);

  const notDraft = checkIsNotDraftOrEmptyStatus(status);
  const isFinished = ["finished", "cancelled"].includes(status);

  return (
    <>
      <CustomizedBox maxWidth={1650} margin={"0 0 1.5rem 0"}>
        {!disabled && !isFinished && (
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography fontWeight={"bold"}>สแกน Barcode</Typography>
          </Box>
        )}
        {!disabled && !isFinished && (
          <Box maxWidth={996}>
            <Grid container spacing={1.5}>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <ControlledTextField
                  label="Scan Barcode"
                  control={barcodeControl}
                  name="barcode"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.shiftKey === false) {
                      onBarcodeSubmitHandler();
                    }
                  }}
                  disabled={!watchWarehouseId || !watchType}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {/* {fields?.length > 0 && ( */}
        <>
          <Box display="flex" justifyContent="space-between">
            <Typography fontWeight={"bold"} mb={2} mt={isFinished ? 0 : 2}>
              รายการสินค้า
            </Typography>
            {/* {notDraft && (
            <CustomizedMenuOptions
              size="medium"
              label="นำออก Barcode"
              options={options}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case `นำออก Excel`:
                    exportBarcodeHandler("xlsx");
                    break;
                  case `นำออก CSV`:
                    exportBarcodeHandler();
                    break;
                  case `พิมพ์ทั้งหมด`:
                    // const traceEntry =
                    //   goodReceiveData?.trace_entry_list as ITraceEntry[];
                    // printBarcodeHandler(traceEntry);
                    break;
                  default:
                }
              }}
            />
          )} */}
          </Box>
          <TableContainer>
            <Table
              sx={{ minWidth: 650, overflow: "scroll" }}
              aria-label="simple table"
            >
              <TableHead
                sx={{
                  bgcolor: "#ecf6fd",
                }}
              >
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell
                      align={header.align ? header.align : "left"}
                      key={index}
                      sx={{
                        px: 1,
                      }}
                      width={header.width}
                    >
                      <Typography
                        fontSize={14}
                        fontWeight={600}
                        width={header.width}
                      >
                        {header.thaiLabel}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((trace, index) => (
                  <Fragment key={trace.id}>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[0]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>{index + 1}</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        align="left"
                        width={
                          (headers && headers.length > 0
                            ? headers[1]?.width || 0
                            : 0) + 16
                        }
                      >
                        {trace.traceability === Traceability.Normal && (
                          <Typography fontSize={14}>
                            {formatDateTimeNoAMPM(trace.scanned_date)}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[1]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            minWidth: "90px",
                            justifyContent: "center",
                          }}
                        >
                          <Avatar
                            alt={trace.item_name}
                            src={
                              trace?.item_img_url ? trace.item_img_url[0] : ""
                            }
                            sx={{
                              width: 60,
                              height: 60,
                              border: "1px solid #BEBEBE",
                              borderRadius: "2px",
                            }}
                            variant="square"
                          >
                            <ImageOutlinedIcon
                              sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                              fontSize="medium"
                            />
                          </Avatar>
                        </Box>
                      </TableCell>
                      <TableCell
                        width={
                          (headers && headers.length > 0
                            ? headers[2]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>
                          {trace.item_unique_id}
                        </Typography>
                        <Typography fontSize={14}>{trace.item_name}</Typography>
                        {/* {goodReceiveData?.main_status === "draft" &&
                        initialTraceItems?.includes(trace.item_unique_id) &&
                        !existingInitialTraceItems?.includes(
                          trace.item_unique_id
                        ) && (
                          <Typography
                            fontSize={12}
                            sx={{
                              mt: 0.5,
                              color: (theme) => theme.palette.error.main,
                            }}
                          >
                            สินค้านี้ถูกลบออกจากระบบแล้ว กรุณาลบสินค้า
                          </Typography>
                        )} */}
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[4]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>
                          {trace.document_item_qty || 0}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[5]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>
                          {trace.posted_qty || 0}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[6]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>
                          {(trace.document_item_qty || 0) -
                            (trace.posted_qty || 0)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        width={
                          (headers && headers.length > 0
                            ? headers[7]?.width || 0
                            : 0) + 16
                        }
                      >
                        {trace.traceability === Traceability.Normal ? (
                          <ControlledNumberTextField
                            fullWidth
                            control={control}
                            name={`trace_entry_list.${index}.qty`}
                            disabled={Boolean(trace.status !== "is_scanned")}
                            error={Boolean(
                              (errors &&
                                errors.trace_entry_list &&
                                errors.trace_entry_list[index] &&
                                errors.trace_entry_list[index]?.qty) ||
                                (watchType !== "other" &&
                                  trace.qty >
                                    (trace.document_item_qty || 0) -
                                      (trace.posted_qty || 0))
                            )}
                            decimalScale={0}
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                            FormHelperTextProps={{
                              style: { fontSize: "10px", textAlign: "end" },
                            }}
                            helperText={
                              trace.scanned_by
                                ? `จำนวนคงคลัง ${
                                    watchTraceEntryList[
                                      index
                                    ]?.all_bin_locations
                                      ?.find(
                                        (bin) =>
                                          bin.id ===
                                          watchTraceEntryList[index]
                                            .source_bin_location_id
                                      )
                                      ?.stock_qty?.toString() || "-"
                                  }`
                                : undefined
                            }
                            sx={{
                              pr: "0.5rem",
                            }}
                            viewMode={disabled || notDraft}
                          />
                        ) : (
                          <TotalPostedQuantityCell nestedIndex={index} />
                        )}
                      </TableCell>
                      <TableCell
                        width={
                          (headers && headers.length > 0
                            ? headers[8]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography fontSize={14}>{trace.uom}</Typography>
                        </Box>
                      </TableCell>
                      <Fragment>
                        <TableCell>
                          {trace.traceability === Traceability.Normal
                            ? "-"
                            : ""}
                        </TableCell>
                        <TableCell>
                          {trace.traceability === Traceability.Normal ? (
                            trace.all_bin_locations &&
                            trace.all_bin_locations.length > 1 &&
                            !disabled ? (
                              <ControlledSelect
                                name={`trace_entry_list.${index}.source_bin_location_id`}
                                control={control}
                                label={""}
                                error={Boolean(
                                  errors.trace_entry_list?.[index]
                                    ?.source_bin_location_id
                                )}
                                helperText={
                                  errors.trace_entry_list &&
                                  errors.trace_entry_list?.[index]
                                    ?.source_bin_location_id &&
                                  errors.trace_entry_list?.[index]
                                    ?.source_bin_location_id?.message
                                }
                                onChange={(e: any) => {
                                  const matchingBinLocation =
                                    trace.all_bin_locations?.find(
                                      (bin) => bin.id === e.target.value
                                    );
                                  if (matchingBinLocation) {
                                    setValue(
                                      `trace_entry_list.${index}.stock_qty`,
                                      matchingBinLocation.stock_qty
                                    );
                                  }
                                }}
                                options={
                                  trace.all_bin_locations?.map((bin) => ({
                                    value: bin.id,
                                    label: bin.name,
                                  })) || []
                                }
                              />
                            ) : (
                              <Typography fontSize={14}>
                                {trace.source_bin_location?.name}
                              </Typography>
                            )
                          ) : null}
                        </TableCell>
                        <TableCell align="center">
                          {trace.scanned_by &&
                            Object.keys(trace.scanned_by).length > 0 && (
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <CustomizedAvatar
                                  avatars={[
                                    {
                                      unique_id:
                                        trace.scanned_by.user_unique_id || "",
                                      first_name:
                                        trace.scanned_by.first_name || "",
                                      last_name:
                                        trace.scanned_by.last_name || "",
                                      img_url: trace.scanned_by.img_url
                                        ? trace.scanned_by.img_url[0]
                                        : "",
                                    },
                                  ]}
                                />
                              </Box>
                            )}
                        </TableCell>
                        <TableCell>
                          {trace.traceability === Traceability.Normal &&
                            trace.barcode}
                        </TableCell>
                      </Fragment>

                      <TableCell>-</TableCell>
                      {!disabled && !notDraft && (
                        <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                    <SerialList
                      nestedIndex={index}
                      disabled={disabled}
                      status={status}
                    />
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
        {/* )} */}
      </CustomizedBox>
    </>
  );
};

export default GoodsReceiveItemList;
